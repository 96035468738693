var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', _vm._b({
    attrs: {
      "height": _vm.style.height,
      "background-color": _vm.style.backgroundColor,
      "border": "1px solid ".concat(_vm.style.borderColor),
      "color": _vm.style.color,
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "border-radius": "1000px",
      "white-space": "nowrap",
      "padding": "0 8px"
    }
  }, 'c-box', _vm.$attrs, false), [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.rightSvgIcon ? _c('c-box', {
    attrs: {
      "margin-left": "8px",
      "height": _vm.style.icon.height,
      "width": _vm.style.icon.width
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.rightSvgIcon,
      "min-height": "100%",
      "height": "100%",
      "min-width": "100%",
      "width": "100%",
      "fill": _vm.style.color
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }