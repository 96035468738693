var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "margin-bottom": _vm.isWithoutMarginBottom ? '0' : _vm.SIZE.other.marginBottom,
      "width": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [_c('c-form-label', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.label,
      expression: "!!label"
    }],
    attrs: {
      "font-size": _vm.SIZE.label.fontSize,
      "color": _vm.isInvalid ? 'danger.400' : _vm.value ? 'primary.400' : '#555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.isRequired === false ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": _vm.SIZE.subLabel.fontSize
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1), _c('c-input-group', {
    attrs: {
      "position": "relative",
      "height": _vm.SIZE.input.height,
      "z-index": "1"
    }
  }, [_vm.hasInputLeftAddon ? _c('c-input-left-addon', {
    attrs: {
      "border-top-left-radius": "8px",
      "border-bottom-left-radius": "8px",
      "height": _vm.SIZE.input.height
    }
  }, [_vm._t("input-left-addon")], 2) : _vm._e(), _c('DatePicker', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "append-to-body": false,
      "input-class": {
        borderLeftNone: _vm.hasInputLeftAddon,
        default: true,
        err: _vm.isInvalid
      },
      "value-type": _vm.valueType,
      "format": _vm.format,
      "type": _vm.type,
      "placeholder": _vm.placeholder,
      "clearable": _vm.clearable,
      "disabled": _vm.isDisabled,
      "font-size": _vm.SIZE.input.fontSize
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('blur');
      }
    },
    scopedSlots: _vm._u([{
      key: "icon-calendar",
      fn: function fn() {
        return [_vm._v("   ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  })], 1), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": _vm.SIZE.helperText.fontSize,
      "font-weight": _vm.SIZE.helperText.fontWeight,
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "color": _vm.helperTextColor
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": _vm.hasHelperTextClick ? 'pointer' : 'auto'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('helper-text-click');
      }
    }
  }, [_vm.helperTextLogo ? _c('c-box', {
    attrs: {
      "min-height": _vm.SIZE.helperText.minHeight,
      "min-width": _vm.SIZE.helperText.minWidth,
      "height": _vm.SIZE.helperText.height,
      "width": _vm.SIZE.helperText.width
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.helperTextLogo,
      "height": "100%",
      "width": "100%",
      "fill": _vm.helperTextColor
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.helperText) + " ")], 1)], 1) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": _vm.SIZE.helperText.fontSize,
      "font-weight": _vm.SIZE.helperText.fontWeight,
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey
    }, [_vm._v(" ● " + _vm._s(invalidTextValue) + " ")]);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }