var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "fit-content"
    }
  }, [_c('BaseChip', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "label": _vm.getLabel,
      "color": _vm.getColor,
      "screen": _vm.screen,
      "right-svg-icon": _vm.getIcon,
      "right-svg-icon-height-and-width": "20px"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }