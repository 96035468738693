<template>
  <c-box
    :height="style.height"
    :background-color="style.backgroundColor"
    :border="`1px solid ${style.borderColor}`"
    :color="style.color"
    display="flex"
    justify-content="center"
    align-items="center"
    border-radius="1000px"
    white-space="nowrap"
    padding="0 8px"
    v-bind="$attrs"
  >
    {{ label }}
    <c-box
      v-if="rightSvgIcon"
      margin-left="8px"
      :height="style.icon.height"
      :width="style.icon.width"
    >
      <inline-svg
        :src="rightSvgIcon"
        min-height="100%"
        height="100%"
        min-width="100%"
        width="100%"
        :fill="style.color"
      />
    </c-box>
  </c-box>
</template>

<script>
import customTheme from '@/styles/custom-theme'

export default {
  name: 'BaseChip',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '-',
    },
    screen: {
      type: String,
      default: 'both',
      validator(value) {
        return [
          'mobile-only',
          'desktop-only',
          'both',
        ].includes(value)
      },
    },
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return [
          'primary',
          'secondary',
          'warning',
          'danger',
        ].includes(value)
      },
    },
    rightSvgIcon: {
      type: String,
      default: '',
    },
    rightSvgIconHeightAndWidth: {
      type: String,
      default: '16.67px',
    },
  },
  computed: {
    style() {
      let height = ['32px', '50px']
      if (this.screen === 'mobile-only') {
        height = ['32px', '32px']
      } else if (this.screen === 'desktop-only') {
        height = ['50px', '50px']
      }

      let iconHeightAndWidth = ['16.67px', '25px']
      if (this.screen === 'mobile-only') {
        iconHeightAndWidth = ['16.67px', '16.67px']
      } else if (this.screen === 'desktop-only') {
        iconHeightAndWidth = ['25px', '25px']
      }

      return {
        backgroundColor: customTheme.colors?.[this.color]?.[50],
        borderColor: customTheme.colors?.[this.color]?.[400],
        color: customTheme.colors?.[this.color]?.[400],
        height,
        icon: {
          height: this.rightSvgIconHeightAndWidth || iconHeightAndWidth,
          width: this.rightSvgIconHeightAndWidth || iconHeightAndWidth,
        },
      }
    },
  },
}
</script>
