var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    directives: [{
      name: "on-clickaway",
      rawName: "v-on-clickaway",
      value: _vm.close,
      expression: "close"
    }],
    style: _vm.wrapperStyles,
    attrs: {
      "id": "wrapper-base-menu",
      "pos": "relative"
    }
  }, [_c('Popper', {
    style: _vm.popperStyles,
    attrs: {
      "id": "dietela-popper-root",
      "trigger": _vm._trigger,
      "force-show": _vm._isOpen,
      "visible-arrow": false,
      "options": {
        placement: _vm.placement,
        modifiers: _vm.modifiers
      }
    }
  }, [_c('c-box', {
    style: _vm.buttonStyles,
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_vm._t("button", null, {
    "toggle": _vm.toggle,
    "isOpen": _vm._isOpen
  })], 2), _c('c-box', {
    staticClass: "popper",
    style: Object.assign({
      minWidth: '100%'
    }, _vm.contentStyles)
  }, [_vm._t("menu", null, {
    "close": _vm.close,
    "isOpen": _vm._isOpen
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }