<template>
  <c-box
    id="wrapper-base-menu"
    v-on-clickaway="close"
    pos="relative"
    :style="wrapperStyles"
  >
    <Popper
      id="dietela-popper-root"
      :trigger="_trigger"
      :force-show="_isOpen"
      :visible-arrow="false"
      :options="{
        placement,
        modifiers
      }"
      :style="popperStyles"
    >
      <c-box
        slot="reference"
        :style="buttonStyles"
      >
        <slot
          name="button"
          :toggle="toggle"
          :is-open="_isOpen"
        />
      </c-box>
      <c-box
        class="popper"
        :style="{ minWidth: '100%', ...contentStyles}"
      >
        <slot
          name="menu"
          :close="close"
          :is-open="_isOpen"
        />
      </c-box>
    </Popper>
  </c-box>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.min.css'

export default {
  name: 'BaseMenu',
  components: {
    Popper,
  },
  mixins: [
    clickaway,
  ],
  props: {
    trigger: {
      type: String,
      default: 'auto',
      validator(value) {
        return [
          'auto',
          'manual',
        ].includes(value)
      },
    },
    placement: {
      type: String,
      default: 'bottom-start',
      validator(value) {
        return [
          'auto',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'right',
          'right-start',
          'right-end',
          'left',
          'left-start',
          'left-end',
        ].includes(value)
      },
    },
    modifiers: {
      type: Object,
      default: () => ({}),
    },
    wrapperStyles: {
      type: Object,
      default: () => ({}),
    },
    buttonStyles: {
      type: Object,
      default: () => ({}),
    },
    contentStyles: {
      type: Object,
      default: () => ({}),
    },
    popperStyles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    _isOpen() {
      if (this.trigger !== 'manual') return undefined
      return this.isOpen
    },
    _trigger() {
      switch (this.trigger) {
        case 'auto':
          return 'clickToOpen'
        case 'manual':
          return 'focus'
        default:
          return 'clickToOpen'
      }
    },
  },
  methods: {
    toggle() {
      if (this.trigger !== 'manual') return
      this.isOpen = !this.isOpen
    },
    close() {
      if (this.trigger !== 'manual') return
      this.isOpen = false
    },
  },
}
</script>

<style scoped>
::v-deep .popper {
  box-shadow: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #E2E8F0;
  border-left: 1px solid #E2E8F0;
  border-right: 1px solid #E2E8F0;
  background-color: white;
  z-index: 2;
}

::v-deep #dietela-popper-root {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
