<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :margin-bottom="isWithoutMarginBottom ? '0' : SIZE.other.marginBottom"
    :width="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      v-show="!!label"
      :font-size="SIZE.label.fontSize"
      :color="isInvalid ? 'danger.400' : value ? 'primary.400' : '#555'"
      font-weight="400"
      font-family="Roboto"
      padding-left="8px"
    >
      {{ label }}
      <c-box
        v-if="isRequired === false"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="SIZE.subLabel.fontSize"
      >
        (Optional)
      </c-box>
    </c-form-label>

    <c-input-group
      position="relative"
      :height="SIZE.input.height"
      z-index="1"
    >
      <c-input-left-addon
        v-if="hasInputLeftAddon"
        border-top-left-radius="8px"
        border-bottom-left-radius="8px"
        :height="SIZE.input.height"
      >
        <slot name="input-left-addon" />
      </c-input-left-addon>
      <DatePicker
        v-model="modelValue"
        v-chakra
        :append-to-body="false"
        :input-class="{
          borderLeftNone: hasInputLeftAddon,
          default: true,
          err: isInvalid,
        }"
        :value-type="valueType"
        :format="format"
        :type="type"
        :placeholder="placeholder"
        :clearable="clearable"
        :disabled="isDisabled"
        :font-size="SIZE.input.fontSize"
        @close="$emit('blur')"
      >
        <template #icon-calendar>
            &nbsp;
        </template>
      </DatePicker>
    </c-input-group>

    <c-form-helper-text
      v-if="isHelper"
      :font-size="SIZE.helperText.fontSize"
      :font-weight="SIZE.helperText.fontWeight"
      display="flex"
      gap="8px"
      align-items="center"
      :color="helperTextColor"
    >
      <c-box
        display="flex"
        gap="8px"
        align-items="center"
        :cursor="hasHelperTextClick ? 'pointer' : 'auto'"
        @click="$emit('helper-text-click')"
      >
        <c-box
          v-if="helperTextLogo"
          :min-height="SIZE.helperText.minHeight"
          :min-width="SIZE.helperText.minWidth"
          :height="SIZE.helperText.height"
          :width="SIZE.helperText.width"
        >
          <inline-svg
            :src="helperTextLogo"
            height="100%"
            width="100%"
            :fill="helperTextColor"
          />
        </c-box>
        {{ helperText }}
      </c-box>
    </c-form-helper-text>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="SIZE.helperText.fontSize"
      :font-weight="SIZE.helperText.fontWeight"
      display="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
      >
        &#9679; {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'
import { isDataEmpty } from '@/utils/is-data-empty'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'BaseInputDate',
  components: {
    DatePicker,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    fullWidth: { // TODO: add example
      type: Boolean,
      default: false,
    },
    helperTextType: {
      type: String,
      default: '',
    },
    helperTextColor: {
      type: String,
      default: '',
    },
    type: { // TODO: add example
      type: String,
      default: 'date',
      validator(value) {
        return [
          'date',
          'datetime',
          'year',
          'month',
          'time',
          'week',
        ].includes(value)
      },
    },
    format: { // TODO: add example
      type: String,
      default: 'YYYY-MM-DD',
    },
    valueType: { // TODO: add example
      type: String,
      default: 'format',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Array],
      default: () => ['sm', 'md'],
    },
  },
  emits: [
    'blur', // TODO: add example
    'input',
    'trigger-change', // TODO: add example
    'helper-text-click',
  ],
  computed: {
    hasInputLeftAddon() {
      return Boolean(this.$slots['input-left-addon'])
    },
    SIZE() {
      const CONST_SIZE = {
        sm: {
          other: {
            marginBottom: '16px',
          },
          label: {
            fontSize: '14px',
          },
          subLabel: {
            fontSize: '12px',
          },
          input: {
            height: '48px',
            fontSize: '14px',
          },
          helperText: {
            fontSize: '12px',
            fontWeight: '400',
            minHeight: '13px',
            minWidth: '13px',
            height: '13px',
            width: '13px',
          },
        },
        md: {
          other: {
            marginBottom: '24px',
          },
          label: {
            fontSize: '16px',
          },
          subLabel: {
            fontSize: '14px',
          },
          input: {
            height: '62px',
            fontSize: '18px',
          },
          helperText: {
            fontSize: '16px',
            fontWeight: '400',
            minHeight: '16px',
            minWidth: '16px',
            height: '16px',
            width: '16px',
          },
        },
      }

      if (typeof this.size === 'string') {
        return CONST_SIZE[this.size]
      }
      return {
        other: {
          marginBottom: this.size.map((s) => CONST_SIZE[s].other.marginBottom),
        },
        label: {
          fontSize: this.size.map((s) => CONST_SIZE[s].label.fontSize),
        },
        subLabel: {
          fontSize: this.size.map((s) => CONST_SIZE[s].subLabel.fontSize),
        },
        input: {
          height: this.size.map((s) => CONST_SIZE[s].input.height),
          fontSize: this.size.map((s) => CONST_SIZE[s].input.fontSize),
        },
        helperText: {
          fontSize: this.size.map((s) => CONST_SIZE[s].helperText.fontSize),
          fontWeight: this.size.map((s) => CONST_SIZE[s].helperText.fontWeight),
          minHeight: this.size.map((s) => CONST_SIZE[s].helperText.minHeight),
          minWidth: this.size.map((s) => CONST_SIZE[s].helperText.minWidth),
          height: this.size.map((s) => CONST_SIZE[s].helperText.height),
          width: this.size.map((s) => CONST_SIZE[s].helperText.width),
        },
      }
    },
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    helperTextLogo() {
      if (this.helperTextType === 'warn') {
        return require('@/assets/icons/icon-circle-warn.svg')
      }
      if (this.helperTextType === 'questionmark') {
        return require('@/assets/icons/icon-circle-questionmark.svg')
      }
      return ''
    },
    hasHelperTextClick() {
      return this.$listeners['helper-text-click']
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
        this.$emit('blur')
      },
    },
  },
  methods: {
    isDataEmpty,
    formatDateV2,
    parseStyle(style) {
      if (typeof style === 'string') {
        return style
      }
      return Object.entries(style).reduce((acc, [key, value]) => {
        acc += `${key}:${value};`
        return acc
      }, '')
    },
  },
}
</script>

<style scoped>
::v-deep .default {
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  border: 1px solid rgba(136, 136, 136, 0.2);
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: 0 20px;
  color: #008C81;
}

::v-deep .default:focus-visible {
  outline: 2px solid #008C81;
}

::v-deep .err {
  outline: 2px solid #e66673;
  color: #D32737;
}

::v-deep .err:focus-visible {
  outline: 2px solid #e66673;
}

::v-deep .default[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}

::v-deep .disabled .mx-icon-calendar {
  opacity: 0.4;
  cursor: not-allowed;
}

::v-deep .mx-datepicker, ::v-deep .mx-input-wrapper {
  width: 100%;
  height: 100%;
  z-index: 99999999999999999;
}

::v-deep .borderLeftNone {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>