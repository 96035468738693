import { render, staticRenderFns } from "./base-input-date.vue?vue&type=template&id=d1767b24&scoped=true&"
import script from "./base-input-date.vue?vue&type=script&lang=js&"
export * from "./base-input-date.vue?vue&type=script&lang=js&"
import style0 from "./base-input-date.vue?vue&type=style&index=0&id=d1767b24&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1767b24",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFormLabel: require('@chakra-ui/vue').CFormLabel, CInputLeftAddon: require('@chakra-ui/vue').CInputLeftAddon, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CText: require('@chakra-ui/vue').CText, CFormControl: require('@chakra-ui/vue').CFormControl})
